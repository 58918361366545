import React from "react"

import Container from "../components/Container"
import Heading from "../components/Heading"
import P from "../components/Paragraph"
import Button from "../components/Button"
import PageHeader from "../components/PageHeader"
import Wave from "../components/Wave"
import SeoHelmet from "../components/Seo"

const Page = () => {
  return (
    <>
      <SeoHelmet
        title="Contact us"
        description="We look forward to hearing about your projects and ideas!"
        url={`http://lapidus.se/contact-us`}
        cover="https://www.datocms-assets.com/9091/1569936712-internetstiftelsenskontorhammarbykaj.png"
      />
      <PageHeader
        title="Let's have Swedish fika"
        subtitle="We look forward to hearing about your projects and ideas! Schedule a meeting or call with us."
      >
        <Button
          variant="primary"
          fontSize="lg"
          as="a"
          href="mailto:hello@lapidus.se"
        >
          {"Get in touch"}
        </Button>
      </PageHeader>
      <Container mt="5rem">
        <img
          width="100%"
          src="https://www.datocms-assets.com/9091/1569936712-internetstiftelsenskontorhammarbykaj.png?w=1248&h=701&fit=crop&auto=format"
          alt="Stockholm"
        />
        <P fontSize="xs">Image by Oathgrove</P>

        <Container size="sm" my="5rem">
          <Heading as="h2" fontSize={["lg", "xl"]}>
            {"Our headquarters"}
          </Heading>
          <P fontSize={["sm", "lg"]}>
            {
              "Lapidus Interactive AB is based at the internet innovation hub Goto10, on the beautiful south side of Stockholm. We are happy to schedule a meeting or lunch to discuss projects and partnerships. The best way to reach us is via email to "
            }
            <a href="mailto:hello@lapidus.se">hello@lapidus.se</a>.
          </P>
        </Container>
      </Container>
      <Wave color="white" variant="bottom3" bg="navy" />
    </>
  )
}

export default Page
